import React from 'react';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';

import Store from '../../client/store';

const Stores = () => (
    <>
        <Router basepath="/stores">
            <Store path="/:slug" />
        </Router>
        <Router basepath="/:lang/stores">
            <Store path="/:slug" />
        </Router>
    </>
);

export default Stores;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
